<template>
  <div>
    <Header :has-login="hasLogin" :username="user.username"/>

    <el-container class="login">
<!--      <el-header>-->
<!--        <img class="mlogo" src="../assets/bro.png" alt="">-->
<!--      </el-header>-->
      <el-main v-show="!hasLogin">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="ruleForm.username" placeholder="test"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input type="password" v-model="ruleForm.password" placeholder="test"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-main>

      <el-main v-show="hasLogin">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <div style="color: #0088ff; font-size: medium; float: left; padding: 3px 0" type="text">用户名</div>
          </div>
          <div class="text item">
            {{user.username}}
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <div style="color: #0088ff; font-size: medium; float: left; padding: 3px 0" type="text">邮箱</div>
            <div class="text item">
              {{user.email}}
            </div>
          </div>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import Header from "../components/Header";
export default {
  name: "Login",
  components: {Header},
  data() {
    return {
      ruleForm: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'change'}
        ]
      },
      user: {
        userId: '',
        username: '',
        avatar: '',
        email: " "
      },
      hasLogin: false
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          //因为在axios中的this可能得不到store
          const _this = this
          //  使用axios发送请求
          this.$axios.post("/login", this.ruleForm).then( res => {
            console.log(res);
            const jwt = res.headers['authorization']
            const userInfo = res.data.data
            console.log(userInfo)

            this.user.id = userInfo.id
            this.user.username = userInfo.username
            this.user.email = userInfo.email
            this.user.avatar = userInfo.avatar

            //把数据共享到store中
            _this.$store.commit("SET_TOKEN", jwt)
            _this.$store.commit("SET_USERINFO", userInfo)

            //获取
            console.log(_this.$store.getters.getUser)
            this.hasLogin = true


          }).catch( res => {
                console.log(res)
              }
          )
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  created() {
    if (this.$store.getters.getUser) {
      console.log("用户信息：" + this.$store.getters.getUser)
      this.user.username = this.$store.getters.getUser.username
      this.user.avatar = this.$store.getters.getUser.avatar
      this.user.email = this.$store.getters.getUser.email

      this.hasLogin = true
    }
  }
}
</script>

<style scoped>
/*.el-header, .el-footer {*/
/*  background-color: #B3C0D1;*/
/*  color: #333;*/
/*  text-align: center;*/
/*  line-height: 60px;*/
/*}*/

/*.el-aside {*/
/*  background-color: #D3DCE6;*/
/*  color: #333;*/
/*  text-align: center;*/
/*  line-height: 200px;*/
/*}*/

.el-main {
  /*background-color: #E9EEF3;*/
  color: #333;
  text-align: center;
  line-height: 160px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.mlogo {
  z-index: 9;
  height: 60px;
}

.demo-ruleForm {
  max-width: 500px;
  margin: auto auto;
}

.login {
  height: 100vh;
}
</style>
